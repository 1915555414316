import { render, staticRenderFns } from "./CustomerGardenerChange.vue?vue&type=template&id=d48b3b62&scoped=true"
import script from "./CustomerGardenerChange.vue?vue&type=script&lang=js"
export * from "./CustomerGardenerChange.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d48b3b62",
  null
  
)

export default component.exports